<template>
  <div
    class="slot-product-frame"
    :class="[`is-${variant}`]"
  >
    <slot />
  </div>
</template>

<script setup>
const variant = inject('variant');

</script>

  <style lang="scss" scoped>
.slot-product-frame {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 20px;
    border: 4px solid;
    border-radius: 8px;
    background: $C_GREEN_BRIGHT;
    cursor: pointer;
    grid-column: auto / span 6;

    &.is-other {
        border-color: $C_GREEN_DARK;
    }

    &.is-saner {
        border-color: $C_PRIMARY;
    }

    &.is-tipp {
        border-color: $C_ORANGE;
        background: $C_ORANGE_BRIGHT;
        cursor: default;
    }

    &.is-campaign-rose {
        border-color: $C_PINK;
        background: $C_PINK_BRIGHT;
    }

    &.is-campaign-orange {
        border-color: $C_ORANGE_STRONG;
        background: $C_ORANGE_STRONG_BRIGHT;
    }

    &.is-newsletter {
        border-color: $C_BLUE;
    }
}

.shop-product-card__content {
    display: flex;
}
  </style>
